/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Suspense, useEffect } from "react";
import { MessagePayload, onMessage } from "firebase/messaging";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import { routes } from "../routes";
import Loader from "../components/common/spinner";
import CustomToast from "../components/CustomToast";
import { AuthProvider } from "../utils/contexts/authDetails";
import { messaging } from "../utils/FirebaseConfig";
import { handleGetFirebaseToken } from "../api";

interface NotificationType {
  notification?: {
    title: String;
    body: String;
  };
  data?: {
    type?: string;
    notification_id?: string;
    id?: string;
    group?: string;
    precinct_id?: string;
  };
}

const renderRoute = ({ id, path, component: Component, RouteType }) => {
  const Wrapper = RouteType || React.Fragment; // Default to no wrapper if RouteType is not specified
  return (
    <Route
      key={id}
      path={path}
      element={
        <Wrapper>
          <Component />
        </Wrapper>
      }
    />
  );
};

const App: React.FC = () => {
  const [toastCount, setToastCount] = useState(0);

  // This is self invoking function that listens for the notification
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onMessageListener = (async () => {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      onMessage(messagingResolve, (payload: MessagePayload) => {
        const convertPayload: NotificationType = payload as object;
        toast.custom(
          (t) => {
            setToastCount((prevCount) => prevCount + 1); // Increment the count
            return (
              <CustomToast
                title={convertPayload?.notification?.title}
                body={convertPayload?.notification?.body}
                data={convertPayload?.data}
                t={{ ...t, index: toastCount }} // Pass the current toastCount as index
              />
            );
          },
          { duration: 90000, position: "top-right" }
        );
      });
    }
  })();

  // Need this to handle FCM token generation when a user manually blocks or allows notification
  useEffect(() => {
    if (
      "Notification" in window &&
      window.Notification?.permission === "granted"
    ) {
      const firebasePermissionToken = localStorage.getItem(
        "firebasePermissionToken"
      );
      if (!firebasePermissionToken) {
        handleGetFirebaseToken();
      }
    }
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <AuthProvider>
        {"Notification" in window && Notification.permission !== "granted" && (
          <div className="flex justify-center">
            <div>
              <span>The app needs permission to show notifications.</span>{" "}
              <span
                className="text-primary underline cursor-pointer"
                onClick={handleGetFirebaseToken}
              >
                Click here to enable push notifications.
              </span>
            </div>
          </div>
        )}
        <ToastContainer
          theme="colored"
          position="bottom-right"
          autoClose={5000}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
        />

        <Routes>
          {routes.map(({ RouteType, ...routeProps }) => {
            if (RouteType === Route) {
              // Directly render the Route for unprotected paths
              return (
                <Route
                  key={routeProps.id}
                  path={routeProps.path}
                  element={<routeProps.component />}
                />
              );
            }
            // Wrap the route with appropriate RouteType (ProtectedRoute or PasswordChangeRoute)
            return renderRoute({ ...routeProps, RouteType });
          })}
        </Routes>
      </AuthProvider>
    </Suspense>
  );
};

export default App;
