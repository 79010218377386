import { put, takeLeading, call } from "redux-saga/effects";
import { Auth } from "aws-amplify";
import url from "../../api";
import {
  signin,
  signinSuccess,
  signinError,
  completeNewPassword,
  completeNewPasswordError,
  completeNewPasswordSuccess,
  logout,
  logoutError,
  logoutSuccess,
  resetPassword,
  resetPasswordError,
  resetPasswordSuccess,
  setNewPassword,
  setNewPasswordError,
  setNewPasswordSuccess,
  mfa,
  mfaError,
  mfaSuccess,
  storeAuthToken,
  setAuthentication,
  setLoading,
  setDispatchInfo,
} from "../actions/authActions";
import { toast } from "react-toastify";
import ROUTES from "../../utils/constants/routes";

let userRef: any;

export const getAuthToken = async () => {
  // check if token is expired....if token is expired use refresh token else use normal token
  // check if token has expired....if token has expired log user out with a message to login again.
  const currentSession = await Auth.currentSession();
  const idToken: any = currentSession.getIdToken();
  let { jwtToken, payload } = idToken;
  const { iss, sub } = payload;

  return { jwtToken, issue: iss, user_id: sub };
};

const handleSignIn = (email: string, password: string): any => {
  return new Promise((resolve, reject) => {
    Auth.signIn(email, password)
      .then((user: any) => {
        resolve(user);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};

const handleCompletePassword = ({ userRef, password }: any): any => {
  return new Promise((resolve, reject) => {
    Auth.completeNewPassword(userRef, password, {})
      .then((user: any) => {
        resolve(user);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};

const handleMfa = ({ userRef, code }: any): any => {
  return new Promise((resolve, reject) => {
    Auth.confirmSignIn(userRef, code, "SMS_MFA")
      .then((user: any) => {
        resolve(user);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};

const handleResendMfa = ({ userRef }: any): any => {
  return new Promise((resolve, reject) => {
    Auth.resendSignUp(userRef)
      .then((user: any) => {
        resolve(user);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};

const handleForgotPassword = (email: string): any => {
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(email)
      .then((data) => {
        resolve(data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};

const handleForgotPasswordSubmit = (
  email: string,
  code: string,
  new_password: string
): any => {
  return new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(email, code, new_password)
      .then((data) => {
        resolve(data);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};

function* signInSaga(action: any): Generator {
  const { email, password, navigate } = action.payload;
  yield put(setLoading(true));
  try {
    const user = yield call(handleSignIn, email, password);
    userRef = user;
    yield put(setLoading(false));
    yield put(signinSuccess(userRef));
    if (userRef.challengeName === "NEW_PASSWORD_REQUIRED") {
      navigate(ROUTES.setPassword);
    } else if (
      userRef.challengeName === "SMS_MFA" ||
      userRef.challengeName === "SOFTWARE_TOKEN_MFA"
    ) {
      navigate(ROUTES.phoneVerification);
    } else {
      const _authToken: any = yield call(getAuthToken);
      yield put(
        storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
      );
      yield put(setAuthentication(true));
      navigate(ROUTES.home);
    }
  } catch (error: Error | any) {
    yield put(setLoading(false));
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(signinError());
    }

    const { message } = error;
    toast.error(message);
    yield put(signinError());
  }
}

function* newPasswordSaga(action: any): Generator {
  const { password, userInfo, navigate } = action.payload;
  yield put(setLoading(true));
  const details = {
    phone_number: userRef.challengeParam.userAttributes.phone_number,
    email: userRef.challengeParam.userAttributes.email,
    user_name: userRef.username,
  };
  try {
    yield call(handleCompletePassword, { userRef, password });
    const email = userRef.challengeParam.userAttributes.email;
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const user = yield call(handleSignIn, email, password);
    userRef = user;
    const dispatchInfo: any = yield call(url.post, `/dispatchers`, {
      ...userInfo,
      ...details,
      user_id: userRef.attributes.sub,
      dispatcher_id: userRef.attributes.sub,
    });
    const { data } = dispatchInfo.data;
    yield put(setLoading(false));
    yield put(setDispatchInfo(data));
    yield put(completeNewPasswordSuccess());
    toast.success("Password change successful. Please login again");
    navigate(ROUTES.home);
  } catch (error: Error | any) {
    yield put(setLoading(false));
    if (error.toString() === "Error: Network Error") {
      yield put(completeNewPasswordError());
      console.log("Please check your network connection and try again");
    }
    const { message } = error;
    yield put(completeNewPasswordError());
    toast.error(message);
  }
}

function* mfaSaga(action: any): Generator {
  const { code, navigate } = action.payload;
  yield put(setLoading(true));
  try {
    const updatedUser = yield call(handleMfa, { userRef, code });
    yield put(mfaSuccess(updatedUser));
    toast.success("Code verfication successful");
    const _authToken: any = yield call(getAuthToken);
    yield put(setLoading(false));
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    yield put(setAuthentication(true));
    navigate(ROUTES.home);
  } catch (error: Error | any) {
    yield put(setLoading(false));
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(completeNewPasswordError());
    }
    if (error.code === "UserNotConfirmedException") {
      yield put(handleResendMfa({ userRef, code }));
      toast.success("A code has been sent to your phone");
      navigate(ROUTES.phoneVerification);
    }
    const { message } = error;
    toast.error(message);
    yield put(mfaError());
  }
}

function* forgotPasswordSaga(action: any): Generator {
  const { email, navigate } = action.payload;
  yield put(setLoading(true));
  try {
    const user = yield call(handleForgotPassword, email);
    yield put(setLoading(false));
    yield put(resetPasswordSuccess(user));
    toast.success("Email sent successfully");
    navigate({
      pathname: ROUTES.changePassword,
      state: { email: email },
    });
  } catch (error: Error | any) {
    yield put(setLoading(false));
    if (error.toString() === "Error: Network Error") {
      yield put(resetPasswordError(error));
      console.log("Please check your network connection and try again");
    }
    const { message } = error;
    yield put(resetPasswordError(message));
    toast.error(message);
  }
}

function* forgotPasswordSubmitSaga(action: any): Generator {
  const { email, navigate, code, password } = action.payload;
  yield put(setLoading(true));
  try {
    const user = yield call(handleForgotPasswordSubmit, email, code, password);
    yield put(setLoading(false));
    yield put(setNewPasswordSuccess(user));
    toast.success("Password changed successfully");
    navigate(ROUTES.dispatchLogin);
  } catch (error: Error | any) {
    yield put(setLoading(false));
    if (error.toString() === "Error: Network Error") {
      yield put(setNewPasswordError(error));
      console.log("Please check your network connection and try again");
    }
    const { message } = error;
    yield put(resetPasswordError(message));
    toast.error(message);
  }
}

function* signOutSaga(): Generator {
  yield put(setLoading(true));
  try {
    yield Auth.signOut();
    yield put(setLoading(false));
    yield put(logoutSuccess());
    toast.success(
      "Logout successful. please login again to access the dispatch application"
    );
  } catch (error: Error | any) {
    yield put(setLoading(false));
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(logoutError());
    }
    const { message } = error;
    toast.error(message);
    yield put(logoutError());
  }
}

function* authSaga() {
  yield takeLeading(signin, signInSaga);
  yield takeLeading(completeNewPassword, newPasswordSaga);
  yield takeLeading(mfa, mfaSaga);
  yield takeLeading(setNewPassword, forgotPasswordSubmitSaga);
  yield takeLeading(resetPassword, forgotPasswordSaga);
  yield takeLeading(logout, signOutSaga);
}

export default authSaga;
