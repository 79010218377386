import { ReactElement } from "react";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { updateNotifications } from "../utils/apis";
import { IoMdCloseCircleOutline } from "react-icons/io";

interface Props {
  title?: String;
  body?: String;
  data?: {
    type?: string;
    notification_id?: string;
    id?: string;
    group?: string;
    precinct_id?: string;
  };
  t: any;
}

export default function CustomToast({
  title,
  body,
  data,
  t,
}: Props): ReactElement {
  const navigate = useNavigate();

  const viewDetails = () => {
    if (data?.id.startsWith("cas-")) {
      navigate(`/cases/update/${data?.id}`);
      updateNotifications(data?.notification_id);
    }
    toast.dismiss(t.id);
  };

  const handleClose = () => {
    toast.dismiss(t.id);
  };

  return (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } w-full shadow-lg pointer-events-auto backdrop-filter backdrop-blur-xl rounded-xl p-3`}
      style={{
        maxWidth: "280px",
        backgroundColor: "rgba(244, 91, 91, 0.8)",
        boxShadow: "2px 4px 20px rgba(0, 0, 0, 0.25)",
        position: "absolute", // Use absolute positioning
        top: `${Number(t.id) + 10}px`, // Increment top by index for vertical stacking
        right: `${Number(t.id) + 10}px`, // Increment right by index for stacking
        zIndex: Number(t.id), // Ensure newer toasts are on top
      }}
    >
      <div className="flex-1">
        <div className="flex items-center mb-4">
          <div className="mr-2">
            <FontAwesomeIcon icon={faInfoCircle} size="1x" color="#fff" />
          </div>
          <div className="w-full flex items-center justify-between">
            <p className="text-white font-semibold uppercase">New case alert</p>
            <p
              className="text-white text-lg -mt-1 cursor-pointer font-black"
              onClick={handleClose}
            >
              <IoMdCloseCircleOutline />
            </p>
          </div>
        </div>
        <div className="flex-1 mb-5">
          <p className="text-lg font-medium text-white">{title}</p>
          <p className="mt-1 text-sm text-white">{body}</p>
        </div>
      </div>
      <div className="flex self-start">
        <button
          onClick={viewDetails}
          className="w-full border border-white rounded-md px-4 py-2 flex items-center justify-center text-sm font-medium text-white focus:outline-none"
        >
          {data?.id.startsWith("cas-") ? "Click to view" : "Dismiss"}
        </button>
      </div>
    </div>
  );
}
