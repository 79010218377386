import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";

interface IProtectedRouteProps {
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated, userDetails } = useSelector(
    (state: RootState) => state.auth
  );

  if (
    isAuthenticated &&
    userDetails?.challengeName !== "NEW_PASSWORD_REQUIRED"
  ) {
    return children ? <>{children}</> : <Outlet />;
  }

  return <Navigate to="/login" replace />;
};

export default ProtectedRoute;
