import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";

interface IPasswordChangeProps {
  children?: React.ReactNode;
}

const PasswordChangeRoute: React.FC<IPasswordChangeProps> = ({ children }) => {
  const { userDetails } = useSelector((state: RootState) => state.auth);

  if (userDetails?.challengeName === "NEW_PASSWORD_REQUIRED") {
    return children ? <>{children}</> : <Outlet />;
  }

  return <Navigate to="/login" replace />;
};

export default PasswordChangeRoute;
