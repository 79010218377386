import axios from 'axios'
import appStore from '../store'
import { setAuthStatus } from '../store/actions/authActions'
import config from '../utils/config'
import { getFirebaseToken } from '../utils/FirebaseConfig'

const url = axios.create({
    baseURL: config.baseUrl,
    timeout: 120000
})

let signal = axios.CancelToken.source()

url.interceptors.request.use(
    (request) => {
        const { token, issuer } = appStore.store.getState().auth
        request.headers.Authorization = `Bearer ${token}`
        request.headers.common['X-Cognito-Issuer'] = issuer
        return request
    }
)

axios.interceptors.response.use(
   undefined, (error) => {
        if (error.response?.status === 'CORS error' || error.response?.data?.message === '401 Unauthorized') {
            appStore.store.dispatch(setAuthStatus(false))
        }
    }
)

/**
 * returns if error is being caused by axios cancel
 * @function
 * @returns {Boolean} - true if the error caused by canceling the request
 */
export const areRequestsCanceled = (err: any) => err && axios.isCancel(err)

/**
 * cancels request
 * @function
 */
export const cancelRequests = () => {
    signal.cancel({ response: { data: { code: 499, message: 'Requests canceled', status: 'CANCELLED' }, isCanceled: true } } as any)
    signal = axios.CancelToken.source()
    return signal
}

export const handleGetFirebaseToken = () => {
  getFirebaseToken().then((firebaseToken: string | undefined) => {
    if (firebaseToken) {
      registerFirebaseToken(firebaseToken);
    }
  });
};

export const registerFirebaseToken = async (permissionToken) => {
  try {
    let userInfo: any = {};
    userInfo = localStorage.getItem("userInfo");
    const precinct = localStorage.getItem("precinct");
    if (userInfo && precinct) {
      userInfo = JSON.parse(userInfo);
      const response = await url.post(
        "notifications/register",
        {
          entity: "dispatchers",
          token: permissionToken,
          id: userInfo?.attributes?.sub,
        },
        {
          headers: {},
        }
      );
      if (response.status === 200 && response.data.status === "success") {
        localStorage.setItem("firebasePermissionToken", permissionToken);
      }
    } else {
      console.log("User not logged in");
    }
  } catch (err) {
    console.log("Error while requesting notifications permission", err);
  }
};

export default url
