import { lazy } from "react";
import { Route } from "react-router-dom";
import ROUTES from "./utils/constants/routes";
import ProtectedRoute from "./components/RouteTypes/ProtectedRoute";
import PasswordChangeRoute from "./components/RouteTypes/PasswordChangeRoute";

const Home = lazy(() => import("./pages/Home"));
const AmbulanceRequest = lazy(() => import("./pages/AmbulanceRequest"));
const AmbulanceProviders = lazy(() => import("./pages/AmbulanceProviders"));
const Cases = lazy(() => import("./pages/Cases"));
const ViewCase = lazy(() => import("./pages/Cases/ViewCase"));
const Login = lazy(() => import("./pages/Login"));
const AdminLogin = lazy(() => import("./pages/Login/AdminLogin"));
const DispatchLogin = lazy(() => import("./pages/Login/DispatchLogin"));
const SetPassword = lazy(() => import("./pages/Login/SetPassword"));
const ResidencyInfo = lazy(() => import("./pages/Login/ResidencyInfo"));
const Verification = lazy(() => import("./pages/Login/Verification"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ChangePassword = lazy(
  () => import("./pages/ForgotPassword/changePassword")
);
const Responders = lazy(() => import("./pages/Responders"));
const Hospitals = lazy(() => import("./pages/Hospitals"));
const Users = lazy(() => import("./pages/Users"));
const Inquiries = lazy(() => import("./pages/Inquiries"));
const Map = lazy(() => import("./pages/Map"));
const Profile = lazy(() => import("./pages/Profile"));
const NotFound = lazy(() => import("./pages/NotFound"));

export const routes = [
  {
    id: 1,
    component: Home,
    path: ROUTES.home,
    RouteType: ProtectedRoute,
  },
  {
    id: 2,
    component: AmbulanceRequest,
    path: ROUTES.ambulanceRequest,
    RouteType: ProtectedRoute,
  },
  {
    id: 3,
    component: AmbulanceProviders,
    path: ROUTES.ambulanceProviders,
    RouteType: ProtectedRoute,
  },
  {
    id: 4,
    component: Users,
    path: ROUTES.users,
    RouteType: ProtectedRoute,
  },
  {
    id: 5,
    component: Profile,
    path: ROUTES.profile,
    RouteType: ProtectedRoute,
  },
  {
    id: 6,
    component: ForgotPassword,
    path: ROUTES.forgotPassword,
    RouteType: Route,
  },
  {
    id: 7,
    component: ChangePassword,
    path: ROUTES.changePassword,
    RouteType: Route,
  },
  {
    id: 8,
    component: Hospitals,
    path: ROUTES.hospitals,
    RouteType: ProtectedRoute,
  },
  {
    id: 9,
    component: Cases,
    path: ROUTES.cases,
    RouteType: ProtectedRoute,
  },
  {
    id: 10,
    component: ViewCase,
    path: ROUTES.updateCase,
    RouteType: ProtectedRoute,
  },
  {
    id: 11,
    component: Responders,
    path: ROUTES.responders,
    RouteType: ProtectedRoute,
  },
  {
    id: 12,
    component: Inquiries,
    path: ROUTES.inquiries,
    RouteType: ProtectedRoute,
  },
  {
    id: 13,
    component: Map,
    path: ROUTES.map,
    RouteType: ProtectedRoute,
  },
  {
    id: 14,
    component: Login,
    path: ROUTES.login,
    RouteType: Route,
  },
  {
    id: 15,
    component: AdminLogin,
    path: ROUTES.adminLogin,
    RouteType: Route,
  },
  {
    id: 16,
    component: DispatchLogin,
    path: ROUTES.dispatchLogin,
    isProtected: false,
    RouteType: Route,
  },
  {
    id: 17,
    component: SetPassword,
    path: ROUTES.setPassword,
    RouteType: PasswordChangeRoute,
  },
  {
    id: 18,
    component: ResidencyInfo,
    path: ROUTES.residencyInfo,
    RouteType: PasswordChangeRoute,
  },
  {
    id: 19,
    component: Verification,
    path: ROUTES.phoneVerification,
    RouteType: Route,
  },
  {
    id: 20,
    component: NotFound,
    path: ROUTES.notFound,
    RouteType: Route,
  },
];

